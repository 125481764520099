import { ref, watch } from 'vue'

// 自定义 hook 用于操作 localStorage
export function useLocalStorage(key, defaultValue = null) {
  // 尝试从 localStorage 获取初始值
  const storedValue = localStorage.getItem(key)
  const value = ref(storedValue ? JSON.parse(storedValue) : defaultValue)

  // 监听 value 的变化，实时更新 localStorage
  watch(
    value,
    (newValue) => {
      console.log('存储缓存:', newValue)

      try {
        if (newValue === null) {
          localStorage.removeItem(key) // 如果值为 null，则删除缓存
        } else {
          localStorage.setItem(key, JSON.stringify(newValue))
        }
      } catch (error) {
        console.error('存储缓存失败:', error)
      }
    },
    {
      immediate: true, // 立即执行一次，确保初始化时从 localStorage 获取值
      deep: true // 深度监听，以确保对象内部属性的变化也能被捕获
    }
  )

  // 返回 getter 和 setter
  return {
    value,
    setValue(newValue) {
      value.value = newValue
    },
    remove() {
      value.value = defaultValue || null // 将 value 设为 null，会触发 watch 来清除 localStorage 中的值
    }
  }
}
